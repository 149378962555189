<template>
  <div class="agency-user-roles animatedBox">
    <div class="container fluid">
      <div class="card">
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :columns="agencyRolesTableColumns"
          :rows="agencyRolesTableData"
          :totalRows="agencyRolesTablePagination.total"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :sort-options="{
            enabled: false
          }"
        >
          <div slot="emptystate">
            <no-data message="There is no data"></no-data>
          </div>
          <div slot="table-actions" class="p-2">
            <button class="btn main mr-2" @click="openAddNewRoleModal">
              <i class="fas fa-plus"></i>
            </button>
            <!-- <button
              :class="{ active: isFilterActive }"
              class="btn toggle"
              @click="filterToggle"
            >
              <i class="fas fa-filter"></i>
            </button> -->
          </div>
          <!-- Table Buttons -->
          <template slot="table-row" slot-scope="props">
            <!-- Action -->
            <span v-if="props.column.field == 'actions'" @click.stop>
              <button
                v-if="props.row.isEditable"
                class="btn mx-1"
                @click="openEditRoleModal(props.row)"
              >
                <i class="fas fa-pen"></i>
              </button>
              <button
                v-if="props.row.isDeletable"
                class="btn danger mx-1"
                @click="openDeleteRoleModal(props.row.id, props.row.name)"
              >
                <i class="fas fa-trash"></i>
              </button>
            </span>
            <span v-if="props.column.field == 'description'">
              <p v-if="props.row.description">{{ props.row.description }}</p>
              <p v-else>No description</p>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
    <!-- ========================= Edit Role Modal ========================= -->
    <modal v-model="isEditRoleModalShown">
      <edit-role
        :roleData="currentEditRole"
        @update="updateAgencyUserRole"
        @close="isEditRoleModalShown = false"
      ></edit-role>
    </modal>
    <!-- ========================= Add Role Modal ========================== -->
    <modal v-model="isAddRoleModalShown">
      <add-role
        @create="createAgencyUserRole"
        @close="isAddRoleModalShown = false"
      ></add-role>
    </modal>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";
import { AgencyRoleModel } from "@/models";

export default {
  components: {
    EditRole: () => import("./EditRole"),
    AddRole: () => import("./AddRole")
  },
  mixins: [vueGoodTable],
  props: {},
  data: function () {
    return {
      agencyRolesTableData: [],
      agencyRolesTablePagination: [],
      agencyRolesTableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center",
          sortable: false,
          width: "150px"
        },
        {
          label: "Name",
          field: "name",
          tdClass: "font-16px"
        },
        {
          label: "Description",
          field: "description"
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      isEditRoleModalShown: false,
      currentEditRole: {},
      isAddRoleModalShown: false
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.getAgencyRoles();
  },
  methods: {
    // ===================== Table related methods =====================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAgencyRoles();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getAgencyRoles();
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    openEditRoleModal(role) {
      this.currentEditRole = role;
      this.isEditRoleModalShown = true;
    },
    openAddNewRoleModal() {
      this.isAddRoleModalShown = true;
    },
    openDeleteRoleModal(id, name) {
      let message = `Are you sure you want to delete role "${name}"?`;
      this.$confirm({
        message: message,
        type: "alert"
      }).then((confirmed) => {
        if (confirmed) {
          this.confirmDeleteRole(id);
        }
      });
    },
    // ======================= API related methods ======================
    async getAgencyRoles() {
      this.$store.commit("setIsLoading", true);

      try {
        let data = await this.$store.dispatch(
          "manageAgency/getAgencyRoles",
          this.$route.params.id
        );
        this.agencyRolesTableData = this._.cloneDeep(data.data);
        this.agencyRolesPagination = this._.cloneDeep(data.meta.pagination);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        throw error;
      }
    },
    async updateAgencyUserRole(roleData) {
      this.isEditRoleModalShown = false; // Close modal
      this.$store.commit("setIsLoading", true);
      try {
        roleData = {
          ...{ agencyId: this.$route.params.id },
          ...roleData
        };

        let response = await this.$store.dispatch(
          "manageAgencyUserRole/updateAgencyUserRole",
          {
            id: roleData.id,
            payload: AgencyRoleModel.postAgencyRolePayload(roleData)
          }
        );
        this.$store.commit("setIsLoading", false);

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Role update successfully."
        });

        this.getAgencyRoles();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        throw error;
      }
    },
    async createAgencyUserRole(payload) {
      this.isAddRoleModalShown = false; // Close modal
      this.$store.commit("setIsLoading", true);
      try {
        let response = await this.$store.dispatch(
          "manageAgencyUserRole/createAgencyUserRole",
          AgencyRoleModel.postAgencyRolePayload(payload)
        );
        this.$store.commit("setIsLoading", false);

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Role created successfully."
        });

        this.getAgencyRoles();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        throw error;
      }
    },
    async confirmDeleteRole(id) {
      try {
        this.$store.commit("setIsLoading", true);
        let response = await this.$store.dispatch(
          "manageAgencyUserRole/deleteAgencyUserRole",
          id
        );
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Role deleted successfully."
        });

        this.getAgencyRoles();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Delete failed, something went wrong."
        });
        throw error;
      }
    },
    async getAllPermissions() {
      try {
        const request = await this.$store.dispatch(
          "manageAgencyUserRole/getPermission",
          {}
        );

        this.roleData.permission = request;
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch permission. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss">
.font-16px {
  font-size: 16px;
}
</style>
